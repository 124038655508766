
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
const Trade = ({ t }) => {
    const trade = t('home:trade', {}, { returnObjects: true });
    return (<section className='trade_section'>
            <div className="container">
                <div className='trade_section_wrapper'>
                    <div className='trade_section_media'>
                        <picture>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/trade/man_working.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/trade/man_working_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/trade/man_working.png`}/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/trade/man_working_x2.png`}/>
                            <img src={`/img/pages/home/trade/man_working.png`} alt="Trade with Option2Trade" width={236} height={287} className='man_working'/>
                        </picture>
                        <picture>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/trade/stock.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/trade/stock_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/trade/stock.png`}/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/trade/stock_x2.png`}/>
                            <img src={`/img/pages/home/trade/stock.png`} alt="Trade with Option2Trade" width={328} height={322} className='stock'/>
                        </picture>
                        <picture>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/trade/trust.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/trade/trust_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/trade/trust.png`}/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/trade/trust_x2.png`}/>
                            <img src={`/img/pages/home/trade/trust.png`} alt="Trusted Trading with Option2Trade" width={158} height={126} className='trust'/>
                        </picture>
                    </div>
                    <div className='trade_section_description'>
                        <h2 className='trade_section_description_title'>
                            {trade.title}
                        </h2>
                        <ul className='trade_section_description_list'>
                            {trade.list.map(item => {
            return <li key={item} className='list_item'>
                                    {item}
                                </li>;
        })}
                        </ul>
                        <Link className='trade_button' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                            <MainButtonBlue text={trade.button}/>
                        </Link>
                    </div>
                </div>
            </div>
        </section>);
};
export default Trade;

    async function __Next_Translate__getStaticProps__18fc42b8ecd__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Trade/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b8ecd__ as getStaticProps }
  