
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
const GetStarted = ({ t }) => {
    const get_started = t('home:get_started', {}, { returnObjects: true });
    return (<section className='get_started'>
            <div className="container">
                <h2 className='get_started_title'>{get_started.title}</h2>
                <div className="get_started_wrapper">
                    <div className='get_started_register'>
                        <div className='get_started_register_padding'>
                            <div className='register_info'>
                                <div>
                                    <h3 className='register_info_title get_started_item_title'>{get_started.register.title}</h3>
                                    <p className='register_info_description get_started_item_description'>{get_started.register.description}</p>
                                </div>
                                <Link className='cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                                    <MainButtonBlue text={get_started.register.register_button}/>
                                </Link>
                            </div>
                            <div className='register_media'>
                                <picture>
                                    <source media="(max-width: 576px)" srcSet={`/img/pages/home/get_started/get_started.webp`} type="image/webp"/>
                                    <source media="(min-width: 577px)" srcSet={`/img/pages/home/get_started/get_started_x2.webp`} type="image/webp"/>
                                    <source media="(max-width: 576px)" srcSet={`/img/pages/home/get_started/get_started.png`}/>
                                    <source media="(min-width: 577px)" srcSet={`/img/pages/home/get_started/get_started_x2.png`}/>
                                    <img src={`/img/pages/home/get_started/get_started.png`} alt="Option2Trade Get started" width={272} height={388} className='register_media_img'/>
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className='right_group'>
                        <div className='get_started_deposit'>
                            <div className='get_started_deposit_padding'>
                                <h3 className='get_started_deposit_title get_started_item_title'>
                                    {get_started.deposit.title}
                                </h3>
                                <p className='get_started_deposit_description get_started_item_description'>
                                    {get_started.deposit.description}
                                </p>
                                <div className='get_started_deposit_popup'>
                                    <div className='popup_header'>
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                    <div className='popup_body'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                            <path opacity="0.12" fillRule="evenodd" clipRule="evenodd" d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z" fill="#3366FF" stroke="#3366FF"/>
                                            <path opacity="0.24" fillRule="evenodd" clipRule="evenodd" d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z" fill="#3366FF" stroke="#3366FF"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M22.4272 30.6849C22.7632 30.6849 23.0288 30.5364 23.2163 30.2474L30.5991 18.6224C30.7397 18.3958 30.7944 18.2239 30.7944 18.0443C30.7944 17.6146 30.5132 17.3333 30.0835 17.3333C29.771 17.3333 29.5991 17.4349 29.4116 17.7318L22.396 28.9114L18.7554 24.1458C18.5601 23.8724 18.3647 23.763 18.0835 23.763C17.6382 23.763 17.3335 24.0677 17.3335 24.4974C17.3335 24.6771 17.4116 24.8802 17.5601 25.0677L21.6147 30.2318C21.8491 30.5364 22.0913 30.6849 22.4272 30.6849Z" fill="#3366FF"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M24 35C30.0751 35 35 30.0751 35 24C35 17.9249 30.0751 13 24 13C17.9249 13 13 17.9249 13 24C13 30.0751 17.9249 35 24 35Z" fill="white"/>
                                            <rect x="22.9896" y="29.1182" width="1.69231" height="11.7508" rx="0.846154" transform="rotate(-140.659 22.9896 29.1182)" fill="#3366FF"/>
                                            <rect x="24.0601" y="27.9404" width="1.69231" height="6.76923" rx="0.846154" transform="rotate(135 24.0601 27.9404)" fill="#3366FF"/>
                                        </svg>
                                        <h4 className='popup_body_title'>{get_started.deposit.result}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='get_started_trading'>
                            <div className='get_started_trading_padding '>
                                <h3 className='get_started_trading_title get_started_item_title'>
                                    {get_started.trading.title}
                                </h3>
                                <p className='get_started_trading_description get_started_item_description'>
                                    {get_started.trading.description}
                                </p>
                                <ul className='get_started_trading_list'>
                                    {get_started.trading.items.map(item => {
            return <li className='list_item' key={item}>
                                            <span className='list_item_text'>{item}</span>
                                        </li>;
        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default GetStarted;

    async function __Next_Translate__getStaticProps__18fc42b8eed__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/GetStarted/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b8eed__ as getStaticProps }
  