
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
import MainButtonTransparent from "../../../common/buttons/MainButtonTransparent";
const Banner = ({ t }) => {
    const banner = t('home:banner', {}, { returnObjects: true });
    return (<section className='banner_section'>
            <div className="container">
                <div className="banner_section_wrapper">
                    <div className='main_banner'>
                        <h1 className='main_banner_title'>{banner.title}</h1>
                        <p className='main_banner_sub_title'>{banner.sub_title}</p>
                        <div className='main_banner_cta'>
                            <Link className='main_banner_cta_trade' href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                                <MainButtonBlue darkBg text={banner.start_button}/>
                            </Link>
                            <Link className='main_banner_cta_demo' href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up?type=1'}>
                                <MainButtonTransparent text={banner.demo_button}/>
                            </Link>
                        </div>
                        <div className='main_banner_platforms'>
                            <h3 className='platforms_text'>{banner.platforms.text}</h3>
                            {banner.platforms.items.map(platform => {
            return <Link key={platform.icon} aria-label={platform.icon} className='platform_item' href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                                    <img src={`/img/pages/home/banner/${platform.icon}.svg`}/>
                                </Link>;
        })}
                        </div>
                    </div>
                    <div className='media_banner'>
                        <div className='media_banner_wrapper'>
                            <picture>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/main_banner.webp`} type="image/webp"/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/main_banner_x2.webp`} type="image/webp"/>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/main_banner.png`}/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/main_banner_x2.png`}/>
                                <img src={`/img/pages/home/banner/main_banner.png`} alt="Option2Trade" width={280} height={299} className='media_banner_main'/>
                            </picture>
                            <picture>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/favorites.webp`} type="image/webp"/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/favorites_x2.webp`} type="image/webp"/>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/favorites.png`}/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/favorites_x2.png`}/>
                                <img src={`/img/pages/home/banner/favorites.png`} alt="Option2Trade Assets" width={359} height={399} className='media_banner_assets'/>
                            </picture>
                            <picture>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/social_trading.webp`} type="image/webp"/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/social_trading_x2.webp`} type="image/webp"/>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/social_trading.png`}/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/social_trading_x2.png`}/>
                                <img src={`/img/pages/home/banner/social_trading.png`} alt="Option2Trade Assets" width={167} height={75} className='media_banner_social'/>
                            </picture>
                            <picture>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/spreads.webp`} type="image/webp"/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/spreads_x2.webp`} type="image/webp"/>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/home/banner/spreads.png`}/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/home/banner/spreads_x2.png`}/>
                                <img src={`/img/pages/home/banner/spreads.png`} alt="Option2Trade Beast Spreads" width={168} height={74} className='media_banner_spreads'/>
                            </picture>
                            <img src={`/img/pages/home/banner/o2t.svg`} alt="Option2Trade Assets" width={84} height={84} className='media_banner_02t'/>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default Banner;

    async function __Next_Translate__getStaticProps__18fc42b8a56__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Banner/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b8a56__ as getStaticProps }
  