
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import MainLayout from "../layouts/main";
import useTranslation from "next-translate/useTranslation";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Loader from "../components/common/Loader";
import Banner from "../components/pages/home/Banner";
import Trade from "../components/pages/home/Trade";
import FeaturedIn from "../components/pages/home/FeaturedIn";
import Why from "../components/pages/home/Why";
import Join from "../components/pages/home/Join";
import Features from "../components/pages/home/Features";
import GetStarted from "../components/pages/home/GetStarted";
import Platforms from "../components/pages/home/Platforms";
export default function Home({ title, description, canonical }) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        let appLoading = new Promise((resolve, reject) => {
            setTimeout(resolve, 300, true);
        });
        Promise.all([appLoading]).then(values => {
            setIsLoading(false);
            setLoaded(true);
        });
    }, []);
    //force force
    return (<MainLayout title={''} description={''} page={'home'}>
            <div className='banner_bg'>
                <Header t={t}/>
                <Banner t={t}/>
            </div>
            <main className='main'>
                <Features t={t}/>
                <Trade t={t}/>
                <FeaturedIn t={t}/>
                <Why t={t}/>
                <Join t={t}/>
                <Platforms t={t}/>
                <GetStarted t={t}/>
            </main>
            <Footer t={t}/>
            <Loader isLoading={isLoading} loaded={loaded}/>
        </MainLayout>);
}

    async function __Next_Translate__getStaticProps__18fc42b84ac__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b84ac__ as getStaticProps }
  