
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Pagination } from "swiper";
import Link from "next/link";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
import 'swiper/css';
import 'swiper/css/pagination';
const Platforms = ({ t }) => {
    const platforms = t('home:platforms', {}, { returnObjects: true });
    const [activeSlide, setActiveSlide] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState({});
    const Item = ({ item }) => {
        return <div className={'platform_item'}>
            <div className='platform_item_body'>
                <p className='platform_item_body_text'>{item.text}</p>
                <Link className='platform_item_body_cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                    <MainButtonBlue text={item.cta}/>
                </Link>
            </div>
            <div className='platform_item_media'>
                <picture>
                    <source media="(max-width: 576px)" srcSet={`/img/pages/home/platforms/${item.img}.webp`} type="image/webp"/>
                    <source media="(min-width: 577px)" srcSet={`/img/pages/home/platforms/${item.img}_x2.webp`} type="image/webp"/>
                    <source media="(max-width: 576px)" srcSet={`/img/pages/home/platforms/${item.img}.png`}/>
                    <source media="(min-width: 577px)" srcSet={`/img/pages/home/platforms/${item.img}_x2.png`}/>
                    <img src={`/img/pages/home/platforms/${item.img}.png`} alt={`Option2Trade ${item.img_alt}`} width={item.img_w} height={item.img_h} className='media_img'/>
                </picture>
            </div>
        </div>;
    };
    return (<section className='platforms_section'>
            <div className="container">
                <div className='platforms_section_wrapper'>
                    <h2 className='platforms_section_title'>{platforms.title}</h2>
                    <div className='slider_buttons_wrapper'>
                        <ul className='slider_buttons'>
                            {platforms.slider_buttons.map((el, i) => {
            return <li className={`slider_buttons_button ${activeSlide === i ? 'active' : ''}`} key={el.key}>
                                    <button onClick={() => {
                    swiperInstance.slideTo(i);
                }}>
                                    <img alt={`Option2Trade ${el.icon} Platform`} width={68} height={68} src={`/img/pages/home/platforms/${el.icon}.svg`}/>
                                        O<span style={{ borderBottom: '3px solid' }}>2</span>T{el.title}
                                    </button>
                                </li>;
        })}
                        </ul>
                    </div>
                    <div className='platforms_swiper_wrapper'>
                        <Swiper onSwiper={(swiper) => setSwiperInstance(swiper)} keyboard={{
            enabled: true,
            onlyInViewport: true
        }} onSlideChange={(e) => {
            setActiveSlide(e.activeIndex);
        }} initialSlide="-1" centeredSlides={false} grabCursor={true} cssMode={true} spaceBetween={10} mousewheel={true} autoplay={true} navigation={{ clickable: true }} breakpoints={{
            576: {
                slidesPerView: 1
            }
        }} lazy={false} modules={[Navigation, Pagination, Keyboard]} slidesPerView={1}>
                            {platforms.slider_items.map(item => {
            return <SwiperSlide key={item.key}>
                                    <Item item={item}/>
                                </SwiperSlide>;
        })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>);
};
export default Platforms;

    async function __Next_Translate__getStaticProps__18fc42b8efb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Platforms/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b8efb__ as getStaticProps }
  