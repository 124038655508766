
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState, useEffect } from 'react';
import { Keyboard, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
const Why = ({ t }) => {
    const why = t('home:why', {}, { returnObjects: true });
    const [swiperInstance, setSwiperInstance] = useState({});
    useEffect(() => {
        if (window.innerWidth < 768) {
        }
        else if (swiperInstance && swiperInstance.initialized) {
            swiperInstance.destroy();
        }
    }, [swiperInstance]);
    return (<section className='why_section'>
            <div className="container">
                <div className='why_section_wrapper'>
                    <h2 className='why_section_title'>{why.title}<br />Option<span className='why_section_title_underline'>2</span>Trade</h2>
                    <div className='why_section_table'>
                        <div className='table_left'>
                            {why.left_titles.map((el, i) => {
            return <div key={el + i} className='table_left_item'>
                                    {el}
                                </div>;
        })}
                        </div>
                        <div className='table_right'>
                            <Swiper onSwiper={(swiper) => setSwiperInstance(swiper)} keyboard={{
            enabled: true,
            onlyInViewport: true
        }} pagination={{
            el: '.swiper-pagination'
        }} centeredSlides={false} grabCursor={true} cssMode={true} spaceBetween={10} mousewheel={true} autoplay={true} navigation={{ clickable: true }} lazy={false} modules={[Navigation, Keyboard]} slidesPerView={2}>
                                {why.right_titles.map((el) => {
            return <SwiperSlide key={el.title}>
                                        <div key={el.title} className='table_right_item'>
                                            <div className='table_right_item_title'>{el.title}</div>
                                            {el.check.map((check, i) => {
                    return <div className='table_right_item_icon' key={'check' + i}>
                                                    {check ?
                            <img src='/img/pages/home/why/check.svg' alt='check'/> :
                            <img src='/img/pages/home/why/uncheck.svg' alt='uncheck'/>}
                                                </div>;
                })}
                                        </div>
                                    </SwiperSlide>;
        })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default Why;

    async function __Next_Translate__getStaticProps__18fc42b8ed9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Why/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b8ed9__ as getStaticProps }
  