
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
import AppStoreButton from "../../../common/buttons/AppStoreButton";
import PlayMarketButton from "../../../common/buttons/PlayMarketButton";
const Join = ({ t }) => {
    const join = t('home:join', {}, { returnObjects: true });
    return (<section className='join_section'>
            <div className="container">
                <div className='join_section_wrapper'>
                    <div className='join_section_description'>
                        <h2 className='join_section_description_title'>
                            {join.title}
                        </h2>
                        <p className='join_section_description_text'>{join.description}</p>
                        <div className='join_section_cta'>
                            <Link className='app_store_cta cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_STORE_URL}>
                                <AppStoreButton />
                            </Link>
                            <Link className='play_market_cta cta' prefetch={false} href={process.env.NEXT_PUBLIC_PLAY_MARKET_URL}>
                                <PlayMarketButton />
                            </Link>
                        </div>
                    </div>
                    <div className='join_section_media'>
                        <picture>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/join/join_work.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/join/join_work_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/join/join_work.png`}/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/join/join_work_x2.png`}/>
                            <img src={`/img/pages/home/join/join_work.png`} alt="Join Option2Trade" width={275} height={298} className='girl_working'/>
                        </picture>
                        <picture>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/join/chat.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/join/chat_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/home/join/chat.png`}/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/home/join/chat_x2.png`}/>
                            <img src={`/img/pages/home/join/chat.png`} alt="Join Option2Trade" width={417} height={298} className='chat'/>
                        </picture>
                    </div>
                </div>
            </div>
        </section>);
};
export default Join;

    async function __Next_Translate__getStaticProps__18fc42b8edf__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Join/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b8edf__ as getStaticProps }
  