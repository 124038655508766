
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const FeaturedIn = ({ t }) => {
    const featured_in = t('home:featured_in', {}, { returnObjects: true });
    return (<section className='featured_in_section'>
            <div className="container">
                <div className="featured_in_section_wrapper">
                    <h2 className='featured_in_section_title'>{featured_in.title}</h2>
                    <ul className='featured_in_section_list'>
                        {featured_in.items.map(item => {
            return <li className='list_item' key={item.icon}>
                                <img className='list_item_icon' src={`/img/pages/home/featured_in/${item.icon}.svg`} alt={`Option2Trade ${item.icon}`} width={item.w} height={item.h}/>
                            </li>;
        })}
                    </ul>
                </div>
            </div>
        </section>);
};
export default FeaturedIn;

    async function __Next_Translate__getStaticProps__18fc42b8ed3__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/FeaturedIn/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fc42b8ed3__ as getStaticProps }
  